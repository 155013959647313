body {
  font-family: "Marvel", sans-serif;
  text-align: center;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.logo {
  float: left;
}

.contact-info {
  float: right;
}

.form-container {
  margin-top: 50px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

input[type="checkbox"] {
  margin-top: 10px;
  margin-bottom: 20px;
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .container {
    padding: 10px;
  }

  .logo,
  .contact-info {
    float: none;
  }
}

